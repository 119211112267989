import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Select } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import axios from 'axios';
import InputMask from "react-input-mask";

export default class ForgotPasswordDialog extends React.Component {

  constructor (props) {
    super(props);
    console.log("ForgotPasswordDialog");
    const {onClose, open } = props;  
    console.log('open = ', open);

    this.state = {
      chave:"",
      dataAniversario: "",
      email: "",
      estado: "",
      dataInputMask:""
    }
  };

  handleForgotPassword = async () => {
    console.log('handleForgotPassword')
    
    if (this.state.chave && this.state.chave !== "" && this.state.dataAniversario && this.state.dataAniversario !== "" 
    && this.state.email && this.state.email !== "" && this.state.estado && this.state.estado !== ""){

      var reqData = {chave:this.state.chave, dataAniversario:this.state.dataAniversario, email:this.state.email, estado:this.state.estado};
      console.log('reqData', reqData);

      const config ={
        contentType: 'application/json',
      };
      try {
        axios.post('https://lsmcc3nlmd.execute-api.us-east-1.amazonaws.com/pwd/',reqData, config)
            .then(response => { console.log('retorno do servico de validacao', response.data);
                                if(response.data && response.data.retorno) {
                                  alert('A nova senha foi solicitada com sucesso. Você receberá um email com a nova senha');
                                  this.props.onClose();
                                }
                                else {
                                  alert("Não foi possível gerar a nova senha. Por favor, verifique os dados informados");
                                  this.props.onClose();
                                }
            });      
      } catch(e) {
          console.log("deu erro na consulta do servico de validacao");
          console.log(e);
          alert("Ocorreu erro ao gerar a nova senha. Por favor, verifique os dados informados");
          this.props.onClose();
      }
    } else {
      alert ("Por favor, informe todos os dados");
      this.props.onClose();
    }
  };


  handleChaveChange = event => {
    this.setState({chave: event.target.value})
  }

  handleDataAniversarioChange = event => {
    let stringValue = event.target.value;
    if(stringValue)
    this.setState({dataAniversario: event.target.value})
  }

  handleEmailChange = event => {
    this.setState({email: event.target.value})
  }


  handleEstadoChange = event => {
    console.log('handleEstadoChange', event.target.value);
    this.setState({estado: event.target.value})
  }

  handleChangeInputMaks = event => {
    console.log('handleChangeInputMaks', event.target.value);
    this.setState({dataInputMask: event.target.value});
  }

  mountEstadoCombo() {
    return(
      <div>
        <br/>
        <InputLabel htmlFor="age-native-simple">Estado</InputLabel>
        <Select
          native
          value={this.state.estado}
          onChange={this.handleEstadoChange}
          inputProps={{
            name: 'age',
            id: 'age-native-simple',
          }}      
        >
          <option value="AC">Acre</option>
          <option value="AL">Alagoas</option>
          <option value="AM">Amazonas</option>
          <option value="AP">Amapá</option>
          <option value="BA">Bahia</option>
          <option value="CE">Ceará</option>
          <option value="DF">Distrito Federal</option>
          <option value="ES">Espírito Santo</option>
          <option value="GO">Goiás</option>
          <option value="IG">Ignorado/exterior</option>
          <option value="MA">Maranhão</option>
          <option value="MG">Minas Gerais</option>
          <option value="MS">Mato Grosso do Sul</option>
          <option value="MT">Mato Grosso</option>
          <option value="PA">Pará</option>
          <option value="PB">Paraíba</option>
          <option value="PE">Pernambuco</option>
          <option value="PI">Piauí</option>
          <option value="PR">Paraná</option>
          <option value="RJ">Rio de Janeiro</option>
          <option value="RN">Rio Grande do Norte</option>
          <option value="RO">Rondônia</option>
          <option value="RR">Roraima</option>
          <option value="RS">Rio Grande do Sul</option>
          <option value="SC">Santa Catarina</option>
          <option value="SE">Sergipe</option>
          <option value="SP">São Paulo</option>
          <option value="TO">Tocantins</option>
        </Select>
      </div>
    );
  }

  render() {
  return (
    <div>
      <Dialog open={this.props.open} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Requisição de nova senha de acesso</DialogTitle>
        <DialogContent>
          <DialogContentText>            
              A fim de requisitar uma nova senha, por favor informe os dados abaixo, para
              conferência dos seus dados pessoais cadastrados no Canal Cliente.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="chave"
            label="Chave de acesso"
            type="text"
            onChange={this.handleChaveChange}
          />
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="E-mail"
            type="email"
            fullWidth
            onChange={this.handleEmailChange}
          />
          <InputMask
            id="dataAniversario"
            mask="99/99"
            value={this.state.dataAniversario}
            onChange={this.handleDataAniversarioChange}
            onBlur={this.handleDataAniversarioChange}
          >
            {() => <TextField label="Aniversário (dd/mm):"/>}
          </InputMask>
          {this.mountEstadoCombo()}

        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={this.handleForgotPassword} color="primary">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
  }
}




ForgotPasswordDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};
