import React from 'react';
import './App.css';
import { Auth } from 'aws-amplify';
import { Form, Button, Input } from "element-react";
import PasswordInput from './components/passwordInput';
import ForgotPasswordDialog from './components/forgotPasswordDialog';
import FirstAccessDialog from './components/firstAccessDialog';
import bg_header from './images/bg_header.png';
import sac from './images/sac.png';
import ComunicadoNovoPortalDialog from './components/comunicadoNovoPortalDialog';


class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      isLogged: false,
      thisUser: null,
      forgotPassword: false,
      firstAccess: false,
      displayComunicadoNovoPortalDialog: true
    };  
  }

  showFirstAccess = () => {
    this.setState({
      firstAccess:true
    });
  }

  closeFirstAccess = () => {
    this.setState({
      firstAccess:false
    });
  }

  showForgotPassword = () => {
    this.setState({
      forgotPassword:true
    });
  }

  closeForgotPassword = () => {
    this.setState({
      forgotPassword:false
    });
  }

  closeComunicadoNovoPortalDialog = () => {
    this.setState({
      displayComunicadoNovoPortalDialog:false
    });
  }

  handleEmailInput = async event => {
    event.preventDefault();
    //forca o signOut  
    try {
        await Auth.signOut();
    } catch (error) {
        console.log('error signing out: ', error);
    }

    try {
      const thisUser = await Auth.signIn(this.state.email);
      //console.log('thisUser', thisUser)
      this.setState({
        thisUser: thisUser,
        isLogged: true
      });
    } catch(e) {
      console.log(e);
      setTimeout( () => window.location.reload(), 2000)
    }
  }

  handleAuthError = () => {
    this.setState({
      email: "",
      isLogged: false,
      thisUser: null,
      forgotPassword: false,
      firstAccess: false
    });      
  }

  render() {
    const { email,isLogged, thisUser } = this.state;
    return (
      <div className="App">
        { /* login */ }
        <div className="container" id="container">
          <div className="form-container">
            <img src={bg_header}/>
            <Form>
              <Form.Item label="Login (mesmo login do Canal Cliente)" style={{ display: (isLogged ? 'none' : 'block') }}>
                <Input type="text" icon="user" style={{ display: (isLogged ? 'none' : 'block') }} placeholder="Login" value={this.state.email} onChange={email => this.setState({email})} />
                <a href="#" style={{ display: (isLogged ? 'none' : 'block') }} onClick={this.showForgotPassword}>Esqueci minha senha</a>
              </Form.Item>
              <Form.Item>
                <Button type="primary" style={{ display: (isLogged ? 'none' : 'block') }} disabled={!email} onClick={this.handleEmailInput}>Acessar</Button>
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="buttonFirst" style={{ display: (isLogged ? 'none' : 'block') }} onClick={this.showFirstAccess}>Primeiro Acesso? Clique Aqui</Button>
              </Form.Item>
              <FirstAccessDialog open={this.state.firstAccess} onClose={this.closeFirstAccess}/> 
              <ForgotPasswordDialog open={this.state.forgotPassword} onClose={this.closeForgotPassword}/> 
              {isLogged && <PasswordInput email={thisUser} authErrorCallback={this.handleAuthError}/>}
              <Form.Item>
                <br/>
                <img src={sac}></img>
              </Form.Item>
            </Form>
            <ComunicadoNovoPortalDialog open={this.state.displayComunicadoNovoPortalDialog} onClose={this.closeComunicadoNovoPortalDialog}/>
          </div>
        </div>
      </div>
    );
  };
}

export default App;
