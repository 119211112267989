import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Auth } from 'aws-amplify';

export default class FirstAccessDialog extends React.Component {

  constructor (props) {
    super(props);
    console.log("FirstAccessDialog");
    const {onClose, open } = props;  
    console.log('open = ', open);

    this.state = {
      cadastradoComSucesso : false,
      chave:""
    };
  };

  handleFirstAccess = async event => {
    console.log('handleFirstAccess')
    event.preventDefault();
    if (this.state.chave && this.state.chave !== ""){
      try {
        console.log('vai chamar a a funcao Auth.forgotPassword para a chave',this.state.chave);
        Auth.forgotPassword(this.state.chave)
          .then(data => {console.log(data); 
                         alert('Usuario ativado com sucesso!'); 
                         this.setState({cadastradoComSucesso : true});
                         this.props.onClose();
                      }
               )
          .catch(err => {console.log(err); alert('Erro ao ativar o usuario')});
      } catch(e) {
        console.log(e);
      }
    } else {
      alert ("Por favor, informe o usuário!");
    }
  };

  handleChaveChange = (event) => {
    this.setState({chave: event.target.value});
  }

  render() {
  return (
    <div>
      <Dialog open={this.props.open} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Primeiro Acesso</DialogTitle>
        <DialogContent>
          <DialogContentText>            
              Para realizar o primeiro acesso pela AWS, é necessário informar o login do Canal Cliente para o sistema fazer o cadastro inicial
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="chave"
            label="Chave de acesso"
            type="text"
            onChange={this.handleChaveChange}    
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="primary">
            Cancelar
          </Button>          
          <Button onClick={this.handleFirstAccess} color="primary">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
  }
}




FirstAccessDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};
