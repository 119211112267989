import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Form, Button, Input } from "element-react";
import { Auth } from 'aws-amplify';
import aws_exports from '../aws-exports';

class PasswordInput extends React.Component {
constructor(props) {
    super(props);
    this.state = { 
        password: '',
        Auth: false,
        authError: false,
        authErrorCallback: this.props.authErrorCallback
    }
}

handlePasswordInput = async event => {
    event.preventDefault();
    try {
       await Auth.sendCustomChallengeAnswer(this.props.email, this.state.password);
       this.isAuth();
    } catch(e) {
        this.setState({authError:true});
        console.log(e);
    }
};

isAuth = async () => {
    try {
        //console.log(Auth);
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const account = { action: 'appstreamSession' };
        const config ={
            contentType: 'application/json',
            headers :{
                Authorization: token
            }
        };
        axios.post(aws_exports.apiAppstreamEndpoint+'/auth', account,config)
            .then(response => this.redirectAppstream(response.data));
        this.setState({ Auth: true });
    } catch(e) {
        this.setState({authError:true});
        console.log(e);
    }
}

redirectAppstream = (data) => {
    if (data) {
        console.log(data.Message);
        window.location.href = data.Message;
    }
};

renderAuthReturn = () => {
    if (this.state.Auth) {
        return <h1>Aguarde....</h1>
    }
    else if (this.state.authError) {
        return (
            <div>
                <h3>Usuário e/ou senha inválido(s)</h3>
                <Button type="primary" onClick={this.props.authErrorCallback}>Voltar</Button>
            </div>
        )
    }
};


render() {   
    const { password } = this.state; 
 return (
      <div> 
        {!this.state.Auth && !this.state.authError ? (
            <div>
                <Form.Item label="Senha">
                    <Input type="password" icon="user" placeholder="senha..." onChange={password => this.setState({password})} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" disabled={!password} onClick={this.handlePasswordInput}>Acessar</Button>
                </Form.Item>
            </div>
        ) : (
            <div>
                {this.renderAuthReturn()}
            </div>

        )}
      </div>
    )
 }
}

export default PasswordInput;

PasswordInput.propTypes = {
    authErrorCallback: PropTypes.func.isRequired
  };