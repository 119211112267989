const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    //"aws_user_pools_id": "us-east-1_pDrx51lPW",
    "aws_user_pools_id": "us-east-1_6GOcmhDU1",
    //"aws_user_pools_web_client_id": "2mmuqoqvq8k60gikqgo6kbrk27",
    "aws_user_pools_web_client_id": "52ln4c5l6o62400r6l3hl22btp",
    //"apiAppstreamEndpoint": "https://o94vfm5kfh.execute-api.us-east-1.amazonaws.com/dev"
    "apiAppstreamEndpoint": "https://s4tu9m8xja.execute-api.us-east-1.amazonaws.com/dev"
};export default awsmobile;
